import { Action, contextHandler, ofType } from '@bees-lite-web/core';
import { BeesCenter, BeesModal } from '@bees-lite-web/honeycomb-web-react';
import { eventContext as hornetEventContext } from '@bees-lite-web/hornet-web';
import 'driver.js/dist/driver.css';
import { useEffect, useRef, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  Subject,
  Subscription,
  fromEvent,
  merge,
  zip,
} from 'rxjs';
import {
  exhaustMap,
  mapTo,
  share,
  skipWhile,
  startWith,
  take,
  tap,
} from 'rxjs/operators';
import './app.scss';
import { useAnalytics } from './context/AnalyticsContext';
import { OfflineProvider } from './context/OfflinePageContext';
import history from './core/browserHistory';
import DebugDrawer from './core/components/DebugDrawer/DebugDrawer';
import { Observer } from './core/components/Observer';
import { displayMessageSubscriber } from './core/displayMessage.subject';
import {
  MELLIFERA_EVENT_TYPE,
  emmiter,
  eventContext as melliferaEventContext,
} from './core/eventContext';
import { StorageAbstract } from './core/storage/storage.abstract';
import { StorageLocalStorage } from './core/storage/storage.localstorage';
import { StorageSessionStorage } from './core/storage/storage.sessionstorage';
import { ActionsChangeLanguageHandler } from './handlers/action/actions.change-language.handler';
import { ActionsChangeLocaleHandler } from './handlers/action/actions.change-locale.handler';
import { ActionsCopyToClipboardHandler } from './handlers/action/actions.copy-to-clipboard.handler';
import { ActionsForgotPasswordHandler } from './handlers/action/actions.forgotpassword.handler';
import { ActionsGetAccessTokenHandler } from './handlers/action/actions.get-access-token.handler';
import { ActionsGoBackHandler } from './handlers/action/actions.go-back.handler';
import { ActionsLaunchURLHandler } from './handlers/action/actions.launch-url.handler';
import { ActionsLoginHandler } from './handlers/action/actions.login.handler';
import { ActionsLogoutHandler } from './handlers/action/actions.logout.handler';
import { ActionsNotificationAudioHandler } from './handlers/action/actions.notification-audio.handler';
import { ActionsOpenOauthJourneyHandler } from './handlers/action/actions.open-oauth-journey.handler';
import { ActionsPartialUpdatesHandler } from './handlers/action/actions.partial-updates.handler';
import { ActionsRegistrationHandler } from './handlers/action/actions.registration.handler';
import { ActionsReloadPageHandler } from './handlers/action/actions.reload-page.handler';
import { ActionsSaveSelectedPocHandler } from './handlers/action/actions.save-selected-poc.handler';
import { ActionsSaveUserPreferenceHandler } from './handlers/action/actions.save-user-preference.handler';
import { ActionsTrackHandler } from './handlers/action/actions.track.handler';
import { ActionsUpdatePageParametersHandler } from './handlers/action/actions.update-page-parameters.handler';
import { ActionsVoidHandler } from './handlers/action/actions.void.handler';
import { ConnectionHandler } from './handlers/connection/connection.handler';
import { DeleteHandler } from './handlers/delete/delete.handler';
import { Error401Handler } from './handlers/error/error.401.handler';
import { RequestErrorHandler } from './handlers/error/request.error.handler';
import { GetHandler } from './handlers/get/get.handler';
import { NavigationHandler } from './handlers/navigation/navigation.handler';
import { PatchHandler } from './handlers/patch/patch.handler';
import { PostHandler } from './handlers/post/post.handler';
import { ReactionHandler } from './handlers/reaction/reaction.handler';
import { ReadyHandler } from './handlers/ready/ready.handler';
import { ResizeHandler } from './handlers/resize/resize.handler';
import { ResponseActionsGoToHandler } from './handlers/response/response.actions.go-to.handler';
import { ResponseActionsMessageHandler } from './handlers/response/response.actions.message.handler';
import { ResponseActionsPartialUpdatesHandler } from './handlers/response/response.actions.partial-updates.handler';
import { ResponseActionsReloadHandler } from './handlers/response/response.actions.reload.handler';
import { ResponseActionsSaveRedirectHandler } from './handlers/response/response.actions.save-redirect.handler';
import { ResponseActionsSaveHandler } from './handlers/response/response.actions.save.handler';
import { ResponseActionsSessionSaveHandler } from './handlers/response/response.actions.sessionsave.handler';
import { ResponseRenderHandler } from './handlers/response/response.render.handler';
import { ScrollHandler } from './handlers/scroll/scroll.handler';
import { SearchHandler } from './handlers/search/search.handler';
import { RxjsAjaxRequesterInfra } from './infrastructure/rxjs.ajax.requester.infra';
import { AuthVerifyScreen } from './screens/dynamic/AuthVerify.screen';
import { DynamicScreen } from './screens/dynamic/Dynamic.screen';
import { ForgotPasswordScreen } from './screens/dynamic/ForgotPassword.screen';
import { HealthCheckScreen } from './screens/dynamic/HealthCheckScreen';
import { LoginScreen } from './screens/dynamic/Login.screen';
import { LogoutScreen } from './screens/dynamic/Logout.screen';
import { NotFoundScreen } from './screens/dynamic/NotFound.screen';
import { RegistrationScreen } from './screens/dynamic/Registration.screen';
import { IAMAzureService } from './services/IAM/IAM.service';
import { KeeperPayload } from './services/keeper/keeper.payload.interface';
import { KeeperService } from './services/keeper/keeper.service';
import { useNewRelicScript } from './services/newRelic/newRelic';
import { PollingService } from './services/polling/polling.service';
import { ResponseActionsLogoutHandler } from './handlers/response/response.actions.logout.handler';

export function App() {
  const analytics = useAnalytics();
  const { listen, location } = history;
  const lastLocation = useRef<string>();
  const [loaded, setLoaded] = useState(false);
  const [routes, setRoutes] = useState<any[]>([]);
  const storage = useRef<StorageAbstract>();
  const backendPayload = useRef(new ReplaySubject<KeeperPayload>(1));
  const connections = useRef(new BehaviorSubject<any>({}));
  const timestampPage = useRef(new Subject<number>());
  const spinnerLoaderRef = useRef(new Subject<boolean>());

  useNewRelicScript();

  useEffect(() => {
    const { pathname, search } = location;
    const url = `${pathname}${search}`;
    const country = localStorage.getItem('country');

    if (url === '/' && !country) {
      localStorage.setItem('country', '"US"');
    }

    lastLocation.current = url;
    return listen((location: any) => {
      const { pathname, search } = location;
      const url = `${pathname}${search}`;

      if (lastLocation.current !== url) {
        lastLocation.current = url;
        backendPayload.current.next();
      }
      timestampPage.current.next(Date.now());
    });
  }, [listen]);

  useEffect(() => {
    const sub = fromEvent(window, 'resize').subscribe(() => {
      emmiter.next(
        new Action({
          type: MELLIFERA_EVENT_TYPE.resize,
          parameters: {
            width: document.body.offsetWidth,
            height: document.body.offsetHeight,
          },
        })
      );
    });
    return () => sub.unsubscribe();
  }, []);

  /**
   * SELLER BUSINESS RULE
   * TODO: Remove it.
   *
   * These informations are used to know if it is the first time the user is on the website
   * If it is the first time, the keeper will send a modal to force interaction
   * The interaction is required to be able to send notifications
   * Obs: This impl SHOULD NOT to be a permanent solution.
   */
  useEffect(() => {
    const firstLoadValue = JSON.parse(
      window.sessionStorage.getItem('firstLoad') || '[]'
    );
    if (Array.isArray(firstLoadValue)) {
      window.sessionStorage.setItem('firstLoad', 'true');
    }
  }, []);

  useEffect(() => {
    const subscription = initHandlers().reduce(
      (subscription, handler) => subscription.add(handler.subscribe()),
      new Subscription()
    );
    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    if (!loaded) return;

    requestNotificationPermission();

    storage.current?.get('routes').then((routes) => {
      setRoutes(routes);
    });
  }, [loaded]);

  const requestNotificationPermission = async () => {
    if (!('Notification' in window)) {
      console.info('This browser does not support notifications.');
    } else {
      const permission = await Notification.requestPermission();
      if (permission !== 'granted') {
        console.error('Permission not granted for Notification');
      } else {
        console.info('Permission granted for Notification');
      }
    }
  };

  const initHandlers = () => {
    storage.current = new StorageLocalStorage();
    const requester = new RxjsAjaxRequesterInfra();
    const melliferaSessionStorage = new StorageSessionStorage();
    const IAM = new IAMAzureService(analytics) as IAMAzureService;
    const keeper = new KeeperService(
      requester,
      storage.current,
      analytics,
      IAM as any,
      melliferaSessionStorage as any
    );

    //? TEMP: Polling service
    new PollingService(keeper);

    const hornetHandlers = contextHandler(hornetEventContext.source, {
      navigation: [new NavigationHandler(storage.current, analytics)],
      get: [new GetHandler(keeper, storage.current)],
      post: [new ActionsPartialUpdatesHandler(), new PostHandler(keeper)],
      patch: [new PatchHandler(keeper)],
      delete: [new DeleteHandler(keeper)],
      ready: [new ReadyHandler(keeper, storage.current)],
      scroll: [new ScrollHandler()],
      search: [new SearchHandler()],
      action: [
        new ActionsSaveUserPreferenceHandler(storage.current),
        new ActionsChangeLocaleHandler(keeper, storage.current),
        new ActionsOpenOauthJourneyHandler(IAM, analytics),
        new ActionsLogoutHandler(storage.current),
        new ActionsSaveSelectedPocHandler(storage.current),
        new ActionsLaunchURLHandler(analytics),
        new ActionsReloadPageHandler(),
        new ActionsGoBackHandler(),
        new ActionsChangeLanguageHandler(keeper, storage.current),
        new ActionsUpdatePageParametersHandler(),
        new ActionsTrackHandler(analytics),
        new ActionsVoidHandler(),
        new ActionsNotificationAudioHandler(),
        new ActionsCopyToClipboardHandler(),
      ],
      reaction: [new ReactionHandler()],
    }).pipe(share());

    const melliferaHandlers = contextHandler(melliferaEventContext.source, {
      error: [
        new Error401Handler(storage.current),
        new RequestErrorHandler(backendPayload.current),
      ],
      navigation: [new NavigationHandler(storage.current, analytics)],
      get: [new GetHandler(keeper, storage.current)],
      response: [
        new ResponseActionsGoToHandler(),
        new ResponseRenderHandler(backendPayload.current),
        new ResponseActionsSaveHandler(storage.current),
        new ResponseActionsSaveRedirectHandler(storage.current),
        new ResponseActionsReloadHandler(),
        new ResponseActionsMessageHandler(),
        new ResponseActionsPartialUpdatesHandler(backendPayload),
        new ResponseActionsSessionSaveHandler(melliferaSessionStorage),
        new ConnectionHandler(connections),
        new ResponseActionsLogoutHandler(storage.current),
      ],
      resize: [new ResizeHandler(keeper, storage.current)],
      action: [
        new ActionsGetAccessTokenHandler(IAM),
        new ActionsForgotPasswordHandler(IAM),
        new ActionsRegistrationHandler(IAM),
        new ActionsLoginHandler(IAM),
        new ActionsUpdatePageParametersHandler(),
      ],
    } as any).pipe(share());

    const loadApp: Observable<any> = zip(
      storage.current.watch('routes').pipe(skipWhile((routes) => !routes)),
      storage.current.watch('x-os').pipe(skipWhile((os) => !os))
    ).pipe(
      tap(() => {
        setLoaded(true);
      })
    );

    const spinnerLoader = merge(
      hornetEventContext.source,
      melliferaEventContext.source
    ).pipe(
      ofType((e) => (e.parameters as any)?.skeleton === 'spinner') as any,
      exhaustMap(() => {
        return merge(hornetHandlers, melliferaHandlers).pipe(
          mapTo(false),
          startWith(true),
          take(2)
        );
      }),
      tap((e) => spinnerLoaderRef.current.next(e))
    );

    return [hornetHandlers, melliferaHandlers, loadApp, spinnerLoader];
  };

  return (
    <>
      <Observer stream={spinnerLoaderRef.current}>
        {(loading) =>
          loading ? (
            <div className="spinner">
              <BeesCenter>
                <div className="spinner-wrapper" />
              </BeesCenter>
            </div>
          ) : null
        }
      </Observer>
      <Observer stream={displayMessageSubscriber}>
        {(message) => (
          <BeesModal
            show={!!message}
            backdrop={false}
            zIndex={'9997'}
            position="bottom-center"
            transition={'slide-bottom'}
          >
            <div className="message">{message}</div>
          </BeesModal>
        )}
      </Observer>
      <OfflineProvider storage={storage.current!}>
        <DebugDrawer />
        <Switch>
          {routes.length
            ? [
                <Route key="auth" path="/auth/verify" exact>
                  <AuthVerifyScreen storage={storage.current!} />
                </Route>,

                <Route key="login" path="/login" exact>
                  <LoginScreen />
                </Route>,

                <Route key="logout" path="/logout" exact>
                  <LogoutScreen />
                </Route>,
                <Route
                  key="auth"
                  path="/auth/verify/customer/account/login"
                  exact
                >
                  <LogoutScreen />
                </Route>,

                <Route
                  exact
                  key="registration"
                  path="/auth/verify/customer/account/registration"
                >
                  <RegistrationScreen />
                </Route>,

                <Route
                  exact
                  key="forgotpassword"
                  path="/auth/verify/customer/account/forgotpassword"
                >
                  <ForgotPasswordScreen />
                </Route>,

                <Route key="healthcheck" path="/health" exact>
                  <HealthCheckScreen />
                </Route>,
              ]
                .concat(
                  routes.map((route) => {
                    return (
                      <Route
                        key={`${route.path}`}
                        path={`/:countryCode/:language${route.path}`}
                      >
                        <DynamicScreen
                          {...route}
                          storage={storage.current!}
                          page={backendPayload.current}
                          timestamp={timestampPage.current}
                        />
                      </Route>
                    );
                  })
                )
                .concat([
                  <Route key="404" path="*">
                    <NotFoundScreen storage={storage.current!} />
                  </Route>,
                ])
            : null}
        </Switch>
      </OfflineProvider>
    </>
  );
}

export default App;
