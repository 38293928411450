import {
  getDebugHeaders,
  getDebugUrl,
} from '../app/core/components/DebugDrawer/KeeperDebug/actions/keeperDebug.actions';
import { IEnvironment } from './environment.d';

const debugHeaders = getDebugHeaders();

export const environment: IEnvironment = {
  production: true,
  debug: true,
  keeperUrl:
    getDebugUrl() ??
    'https://services.dtc.sit.mybees-platform.dev/api/dtc-seller',
  headers: {
    ...(typeof debugHeaders === 'object' ? debugHeaders : {}),
    'x-app-version': '1.19.0',
    'x-get-keys': 'true',
  },
  newRelic: 'SIT',
};
