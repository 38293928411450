import { Action } from '@bees-lite-web/core';
import { AnalyticsBrowser } from '@segment/analytics-next';
import { environment } from '../../../environments/environment';
import { emmiter, MELLIFERA_EVENT_TYPE } from '../../core/eventContext';
import { Throwable } from '../../core/interfaces/maybe.type';
import { StorageAbstract } from '../../core/storage/storage.abstract';
import { IAMAbstractService } from '../IAM/IAM.abstract.service';
import { RequesterInfraAbstract } from '../requester.infra.abstract';
import { ResponseInfraInterface } from '../response.infra.interface';
import { KeeperAbstractService } from './keeper.abstract.service';
import { KeeperPayload } from './keeper.payload.interface';
import { ROUTES } from './routes';

export enum ScreenName {
  ACCOUNT_INFO_PAGE = 'ACCOUNT_INFO_PAGE',
  ACCOUNTING_PAGE = 'ACCOUNTING_PAGE',
  DEALS_PAGE = 'DEALS_PAGE',
  FORGOT_PASSWORD_PAGE = 'FORGOT_PASSWORD_PAGE',
  LOGIN_PAGE = 'LOGIN_PAGE',
  ORDER_DETAILS_PAGE = 'ORDER_DETAILS_PAGE',
  ORDERS_LIST = 'ORDERS_LIST',
  ORDERS_MAP_PAGE = 'ORDERS_MAP_PAGE',
  PENDING_ORDERS = 'PENDING_ORDERS',
  PRODUCT_INVENTORY = 'PRODUCT_INVENTORY',
  STORE_LIST = 'STORE_LIST',
  REGISTRATION_PAGE = 'REGISTRATION_PAGE',
  HEALTH = 'HEALTH',
}

export class KeeperService extends KeeperAbstractService {
  constructor(
    public requester: RequesterInfraAbstract,
    public storage: StorageAbstract,
    public analytics: AnalyticsBrowser | undefined,
    public iam?: IAMAbstractService,
    public sessionStorage?: StorageAbstract
  ) {
    super();
    this.setHeaders({
      'x-segment-id': '',
      'x-fcm-token': '',
      'x-poc-id': '',
      'x-previous-page': '',
      Authorization: '',
      'x-token-type': '',
      'x-user-preferences': '',
      'track-targeting': 'true',
      'x-refresh-token': '',
      'x-last-journey': '',
    });
  }

  configure(): Promise<Throwable<KeeperPayload>> {
    return this.wrappRequest(() =>
      this.requester.get<KeeperPayload>(ROUTES.config())
    );
  }

  async getOffilinePages(): Promise<Throwable<KeeperPayload>> {
    const { country, language } = await this.getLocale();

    return this.wrappRequest(() =>
      this.requester.get<KeeperPayload>(ROUTES.offline(country, language))
    );
  }

  get(url: string): Promise<Throwable<KeeperPayload>> {
    return this.wrappRequest(() =>
      this.requester.get<KeeperPayload>(
        url.includes('?')
          ? url + window.location.search.replace('?', '&')
          : url + window.location.search
      )
    );
  }

  delete(url: string): Promise<Throwable<KeeperPayload>> {
    return this.wrappRequest(() => this.requester.delete<KeeperPayload>(url));
  }

  post(url: string, parameters: any): Promise<KeeperPayload> {
    return this.wrappRequest(() =>
      this.requester.post<KeeperPayload>(
        url + window.location.search,
        parameters
      )
    );
  }

  patch(url: string, parameters: any): Promise<KeeperPayload> {
    return this.wrappRequest(() =>
      this.requester.patch<KeeperPayload>(
        url + window.location.search,
        parameters
      )
    );
  }

  setHeaders(headers: Record<string, any>) {
    this.requester.configureHeader({
      ...(headers ?? {}),
      ...environment.headers,
    });
  }

  async login(
    user: string,
    password: string
  ): Promise<Throwable<KeeperPayload>> {
    const { country, language } = await this.getLocale();

    return this.wrappRequest(() =>
      this.requester.post<KeeperPayload>(ROUTES.login(country, language), {
        user,
        password,
      })
    );
  }

  convertValueToObject(value: any){
    try {
      return JSON.parse(value || "''");
    } catch (error) {
      console.error('Error to parse value to object', error);
      return "''";
    }
  }

  private async wrappRequest(
    request: () => Promise<ResponseInfraInterface<KeeperPayload>>,
    throwable = false
  ): Promise<Throwable<KeeperPayload>> {
    const lastRoute = (await this.storage.get('lastRoute')) as string;
    const userPreferences = await this.storage.get('userPreference');
    const firstLoad = await this.sessionStorage?.get('firstLoad');
    const anonymousId = (await this.storage.get('ajs_anonymous_id')) || '';
    if (this.analytics) {
      (await this.analytics.user()).anonymousId();
    }

    const searchParams = new URLSearchParams(window.location.href);
    const lastRouteParam = searchParams.get('lastRoute') as string;

    const auth = await this.storage.get('auth');
    const currentPage = await this.storage.get('currentRoute');

    if (auth) {
      this.setHeaders({
        Authorization: 'Bearer ' + auth.token,
        'x-token-type': auth.tokenType,
        'x-poc-id': await this.storage.get('activePocId'),
        'x-segment-id': anonymousId,
        'x-previous-page': this.getPage(
          lastRouteParam ? `/${lastRouteParam}` : lastRoute
        ),
        'x-user-preferences':
          JSON.stringify({ ...userPreferences, firstLoad }) || '',
        'x-current-page': window.location.pathname,
        'x-refresh-token': auth.refreshToken,
        'x-last-journey':  this.convertValueToObject(localStorage.getItem('lastJourney')),
      });
    }

    const req = request()
      .then(this.toResponseObject)
      .then(this.emmitResponseRequesterEvent);

    return throwable
      ? req
      : req.catch((err: any) => {
          return this.emmitResponseErrorEvent(err);
        });
  }

  // TODO: As a framework Mellifera should'not have seller information
  private getPage(page: string): string {
    const PAGES: any = {
      '/account-information': ScreenName.ACCOUNT_INFO_PAGE,
      '/deals': ScreenName.DEALS_PAGE,
      '/order-list': ScreenName.ORDERS_LIST,
      '/orders-map': ScreenName.ORDERS_MAP_PAGE,
      '/order-details': ScreenName.ORDER_DETAILS_PAGE,
      '/pending-orders': ScreenName.PENDING_ORDERS,
      '/store-list': ScreenName.STORE_LIST,
      '/inventory': ScreenName.PRODUCT_INVENTORY,
      '/auth/verify/customer/account/registration':
        ScreenName.REGISTRATION_PAGE,
      '/auth/verify/customer/account/forgotpassword':
        ScreenName.FORGOT_PASSWORD_PAGE,
      '/health': ScreenName.HEALTH,
    };

    return PAGES[page] || 'unknown';
  }

  private emmitResponseRequesterEvent(response: any): Promise<KeeperPayload> {
    emmiter.next(
      new Action({
        type: MELLIFERA_EVENT_TYPE.response,
        eventTrigger: 'ajax',
        parameters: response,
      })
    );
    return response;
  }

  private emmitResponseErrorEvent(response: any): Promise<KeeperPayload> {
    emmiter.next(
      new Action({
        type: MELLIFERA_EVENT_TYPE.error,
        eventTrigger: 'ajax',
        parameters: response,
      })
    );
    return response;
  }

  private toResponseObject(
    response: ResponseInfraInterface<KeeperPayload>
  ): KeeperPayload {
    return response.response;
  }

  private async getLocale() {
    const country = await this.storage.get('country');
    const language = await this.storage.get('language');
    return { country, language };
  }

  //? better name?
  private async validateToken() {
    if (
      window.location.href.includes('/auth/verify') ||
      window.location.href.includes('/core_config')
    ) {
      return;
    }

    await this.iam?.validateToken();
  }
}
