import { Action, IEventHandler, ofType } from '@bees-lite-web/core';
import { Observable, Subject } from 'rxjs';
import { AjaxError } from 'rxjs/ajax';
import { concatMap } from 'rxjs/operators';
import { KeeperPayload } from '../../services/keeper/keeper.payload.interface';
import { StorageAbstract } from '../../core/storage/storage.abstract';

export interface IErrorParams extends AjaxError {
  response: KeeperPayload;
}

export class Error401Handler implements IEventHandler<IErrorParams> {
  constructor(
    private store: StorageAbstract,
  ) {}

  handler(
    source: Observable<Action<'error', IErrorParams>>
  ): Observable<Action<'error', IErrorParams>> {
    return source.pipe(
      ofType((e) => e.parameters?.status === 401),
      concatMap(async (e) => {
        await this.store.clear();
        window.sessionStorage.clear();
        window.location.href = '/logout';
        return e;
      })
    );
  }
}
