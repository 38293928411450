import { logoutHandler } from '../app/handlers/logout/logout-handler';
import {
  MelliferaPersistentNotificationServiceSettings,
  MessageType,
} from './worker.interface';

interface PushNotificationParams {
  type: 'push';
  params: {
    title: string;
    body: string;
    onClick: 'focus' | 'close';
  };
}

interface PushAndSoundParameters {
  type: 'push_and_sound';
  params: PushNotificationParams;
}

enum NotificationTypes {
  SOUND = 'SOUND',
  PUSH = 'PUSH',
  PUSH_AND_SOUND = 'PUSH_AND_SOUND',
}

export class PersistentNotificationService {
  private readonly DEFAULT_INGNORE_URLS = [
    'core_config',
    'account-create',
    'login',
    'auth',
    'verify',
    'country-error',
  ];
  private readonly DEFAULT_INTERVAL_TIME = 30000;
  private readonly reqContext = {
    url: this.settings.url,
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      'x-os': 'web',
      'x-previous-page': 'unknown',
      'x-token-type': 'jwt',
      'X-App-Version': '1.2.0.local',
      'X-Get-Keys': true,
      'X-Poc-Id': undefined,
      Authorization: '',
      'x-segment-id': undefined,
      'x-user-preferences': undefined,
      'x-current-page': undefined,
      'x-refresh-token': '',
      'x-last-journey': '',
    },
  };

  constructor(
    private readonly workerRef: Worker,
    private readonly settings: MelliferaPersistentNotificationServiceSettings
  ) {
    this.startPolling();
  }

  async messageHandler(message: MessageEvent<any>) {
    try {
      await this.handleMessage(message);
    } catch (error) {
      this.log('Error on messageHandler', error);
      this.workerRef.postMessage({
        error,
        type: 'UNEXPECTED_ERROR',
      });
    }
  }

  async handleMessage(message: MessageEvent<any>) {
    const type = message.data.type;
    const recipient = message.data.recipient;
    if (type !== MessageType.INIT && recipient !== this.settings.recipient)
      return;

    const params = message.data.params;

    switch (type) {
      case MessageType.REQ_RESPONSE:
        await this.handleServerResponse(params);
        break;
      case MessageType.REQ_ERROR:
        await this.handleServerResponse({ error: true, params });
        break;
      case MessageType.REQ_AUTHORIZATION_ERROR:
        await logoutHandler();
        break;
      default:
        this.log(`The worker received an unknown event called ${type}`);
        this.handleUnknownMessage(type, params);
    }
  }

  private async handleServerResponse(params: any) {
    params?.error
      ? await this.handleErrorResponse(params)
      : await this.handleSuccessResponse(params);
  }

  private async handleSuccessResponse(response: any) {
    try {
      const jsonResponse = JSON.parse(response);
      if (jsonResponse.action?.save?.auth) {
        const saveObject = jsonResponse.action?.save?.auth;
        window.localStorage.setItem('auth', JSON.stringify(saveObject));
        return;
      }
      if (jsonResponse?.action?.logout) {
        await logoutHandler();
        return;
      }
      if (!jsonResponse.action.notification) return;
      const notification = jsonResponse.action.notification;
      this.notificationHandler(notification);
    } catch (error) {
      this.log('Error parsing response', response, error);
    }
  }

  private skip() {
    return this.isIgnoredUrl(true) || !this.isValidCountryAndLanguage();
  }

  private async handleErrorResponse(error: any) {
    this.log(error);
  }

  private handleUnknownMessage(type: string, params: string) {
    this.log(`The worker received an unknown event`, type, params);
  }

  private startPolling() {
    const interval = this.settings.pollInterval || this.DEFAULT_INTERVAL_TIME;
    setInterval(async () => {
      try {
        if (!this.skip()) {
          await this.sendMessage(
            this.workerRef,
            MessageType.FETCH,
            this.reqContext
          );
        } else {
          this.log('Skipping polling interval');
        }
      } catch (error) {
        this.log('Error in polling interval', error);
      }
    }, interval);
  }

  private async sendMessage(
    workerRef: Worker,
    type: MessageType,
    params?: any
  ): Promise<void> {
    await this.setReqConfig();
    workerRef.postMessage({
      recipient: this.settings.recipient,
      type,
      params,
    });
  }

  private async notificationHandler(params: any) {
    if (!this.settings.soundUrl) {
      console.error(
        '[NOTIFICATION MODULE ERROR] eventSourceUrl or soundUrl is not defined. Notification module not initialized.'
      );
      return;
    }

    if (!params || !Object.keys(params).length) return;

    switch (params.type) {
      case NotificationTypes.SOUND:
        this.playSound();
        break;
      case NotificationTypes.PUSH:
        this.showPushNotification(params.params as any);
        break;
      case NotificationTypes.PUSH_AND_SOUND:
        this.pushAndSound(params.params as PushAndSoundParameters['params']);
        break;
    }
  }

  private playSound() {
    try {
      const audio = new Audio(this.settings.soundUrl);
      audio
        ?.play()
        .catch(
          (reason) =>
            `Sound tried to play before screen interaction. Error: ${reason}}`
        );
    } catch (error) {
      console.error(error);
    }
  }

  private showPushNotification(params: PushNotificationParams['params']) {
    try {
      if (Notification.permission === 'granted') {
        const notification = new Notification(params.title, {
          body: params.body,
        });

        if (params.onClick === 'focus') {
          notification.onclick = () => {
            window.focus();
            notification.close();
          };
        }

        if (params.onClick === 'close') {
          notification.onclick = () => {
            notification.close();
          };
        }
      }
    } catch (error) {
      this.log(error);
    }
  }

  private pushAndSound(params: PushAndSoundParameters['params']) {
    this.playSound();
    this.showPushNotification(params as any);
  }

  private async getLocalStorageData() {
    const getFromLStorage = (key: string) => {
      return window.localStorage.getItem(key) || '';
    };
    return {
      userPreference: getFromLStorage('userPreference'),
      authObj: getFromLStorage('auth'),
      anonymousId: getFromLStorage('ajs_anonymous_id'),
      country: getFromLStorage('country'),
      language: getFromLStorage('language'),
      currentPage: window.location.pathname,
      lastJourney: this.convertValueToObject(
        localStorage.getItem('lastJourney')
      ),
      segmentId: this.convertValueToObject(
        localStorage.getItem('ajs_anonymous_id')
      ),
    };
  }

  convertValueToObject(value: any) {
    try {
      return JSON.parse(value || "''");
    } catch (error) {
      console.error('Error to parse value to object', error);
      return "''";
    }
  }

  private async setReqConfig() {
    const storageData: any = await this.getLocalStorageData();
    await this.setReqHeaders(storageData);
    await this.setReqUrl(storageData);
  }

  private async setReqHeaders(storageData: any) {
    const authObj = JSON.parse(storageData.authObj || '{}');
    const token = authObj.token || '';

    if (token) {
      this.reqContext.headers['Authorization'] = `Bearer ${token}`;
      this.reqContext.headers['x-refresh-token'] = authObj?.refreshToken || '';
    }
    this.reqContext.headers['x-segment-id'] = storageData.anonymousId as any;
    this.reqContext.headers['x-user-preferences'] =
      storageData.userPreference as any;
    this.reqContext.headers['x-current-page'] = storageData.currentPage as any;
    this.reqContext.headers['x-last-journey'] = storageData.lastJourney as any;
    this.reqContext.headers['x-segment-id'] = storageData.segmentId as any;
  }

  private async setReqUrl(storageData: any) {
    this.reqContext.url = this.settings.url
      .replace(/\{\{country\}\}/g, JSON.parse(storageData.country))
      .replace(/\{\{language\}\}/g, JSON.parse(storageData.language));
  }

  private isIgnoredUrl(includeRoot = false) {
    if (includeRoot) {
      if (window.location.pathname === '/') {
        return true;
      }
    }

    const urlsToCheck = [
      ...this.DEFAULT_INGNORE_URLS,
      ...this.settings.ignoredUrls,
    ];
    return urlsToCheck.some((url) => window.location.href.includes(url));
  }

  private isValidCountryAndLanguage() {
    const language = window.localStorage.getItem('language');
    const country = window.localStorage.getItem('country');

    if (!language || !country) {
      this.log(
        'Mellifera: Persistent notification services not started.',
        'No language or country set'
      );
      return false;
    }
    return true;
  }

  private log(...args: any[]) {
    console.log(`[${this.settings.recipient}]: `, ...args);
  }
}
