import { Action, IEventHandler, ofType } from '@bees-lite-web/core';
import { Observable } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { StorageAbstract } from '../../core/storage/storage.abstract';
import { KeeperPayload } from '../../services/keeper/keeper.payload.interface';

export class ResponseActionsLogoutHandler
  implements IEventHandler<KeeperPayload>
{
  constructor(private storage: StorageAbstract) {}

  handler(
    source: Observable<Action<'response', KeeperPayload>>
  ): Observable<Action<'response', KeeperPayload>> {
    return source.pipe(
      ofType((e) => !!e?.parameters?.action?.logout),
      concatMap(async (e) => {
        await this.storage.clear();
        window.sessionStorage.clear();
        window.location.href = '/logout';
        return e;
      })
    );
  }
}
