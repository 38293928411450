/* eslint-disable @typescript-eslint/ban-ts-comment */
import { environment } from '../../../environments/environment';
import { KeeperAbstractService } from '../keeper/keeper.abstract.service';
enum IEventType {
  REQUEST = 'req',
  NAVIGATE = 'nav',
}

export class PollingService {
  private readonly TAB_ID = (crypto as any).randomUUID();
  private readonly SNS_POLLING_INTERVAL = 10000;
  private readonly POLLING_TIME_TO_WAITING_LOAD_LOCAL_STORAGE_VALUES = 20000;
  private readonly RECONNECT_ATTEMPT_MINUTES = 5;
  private readonly STORAGE_SNS_ENABLED_KEY = 'snsEnabled';
  private readonly SNS_IS_DISABLED_MESSAGE = 'sns is disabled';

  private readonly KEEPER_POLLING_INTERVALS: { [status: string]: number } = {
    pending: 5000,
    confirmed: 60000,
    inTransit: 60000,
    delivered: 900000,
  };

  private url: string | null = null;
  private token: string | null = null;
  private syncCounter = false;
  private serviceUnavailable = false;
  private unavailableTimestamp = 0;
  private country: string | null = null;
  private language: string | null = null;

  constructor(private readonly keeperService: KeeperAbstractService) {
    if ((window as any).isPollingActive) return;
    (window as any).isPollingActive = true;
    setTimeout(()=>  this.init(), this.POLLING_TIME_TO_WAITING_LOAD_LOCAL_STORAGE_VALUES);
  }

  private init() {
    (window as any).isPollingActive = true;
    const loop = () => setTimeout(() => this.init(), this.SNS_POLLING_INTERVAL);
    const isPedingOrdersPage =
      window.location.pathname.includes('pending-orders');   

    if (!isPedingOrdersPage) {
      loop();
      return;
    }

    this.setCountryAndLanguageAndToken();

    const isSNSEnabled = Boolean(this.load(this.STORAGE_SNS_ENABLED_KEY));

    if (
      !this.allowRequest() ||
      !this.hasCountryAndLanguageAndToken() ||
      !isSNSEnabled
    ) {
      this.keeperPollingBackupHandler(new Error(this.SNS_IS_DISABLED_MESSAGE));
      return;
    }

    this.clearAllPollingDivPartialTimerUpdate();

    const headers = {
      Authorization: `Bearer ${this.token}`,
    } as any;

    if (this.syncCounter) {
      headers.sync = true;
    }

    fetch(this.url!, {
      headers,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error, status=${response.status}`);
        }
        return response.json();
      })
      .then((actions) => this.messageHandler(actions))
      .catch((error) => this.keeperPollingBackupHandler(error))
      .finally(() => {
        if (!this.serviceUnavailable) loop();
      });
  }

  hasCountryAndLanguageAndToken() {
    return !!(this.country && this.language && this.token);
  }

  private load(key: string) {
    try {
      return JSON.parse(localStorage.getItem(key) || '""');
    } catch {
      return null;
    }
  }

  private allowRequest(): boolean {
    const activePocId = this.load('activePocId');
    const userPreference = this.load('userPreference');
    const sessionId = userPreference?.id;
    const snsUrlTemplate = this.load('snsUrl');

    if (!(snsUrlTemplate && activePocId && sessionId)) {
      return false;
    }

    this.url = snsUrlTemplate
      .replace(/{{pocId}}/g, activePocId)
      .replace(/{{sessionId}}/g, `${sessionId}-${this.TAB_ID}`)
      .replace(/{{country}}/g, this.country)
      .replace(/{{language}}/g, this.language);

    return true;
  }

  private messageHandler(event: any): void {
    const actions = event.content;

    if (!actions || (actions && !Array.isArray(actions))) {
      this.log(`Event format is invalid. Event: ${actions}`);
      return;
    }

    this.handleMessageCounter(event.counter);

    if (!actions.length) {
      return;
    }

    for (const action of actions) {
      if (typeof action !== 'object') return;

      if (action.type === IEventType.REQUEST) {
        if (!window.location.pathname.includes('pending-orders')) return;
        // @ts-ignore
        this.keeperService[action.method](action.url, {});
      } else if (action.type === IEventType.NAVIGATE) {
        window.location.href = action.url;
      } else {
        this.log(`Action type not found. Action: ${action}`);
      }
    }
  }

  private handleMessageCounter(value: number) {
    const currentValue = this.messageCounterOperations('get');

    if (this.syncCounter) {
      this.messageCounterOperations('set', value + 1);
      this.syncCounter = false;
      return;
    }

    if (currentValue !== value || currentValue === 0) {
      this.syncCounter = true;
    }

    if (currentValue === value) {
      this.messageCounterOperations('increase');
    }
  }

  private messageCounterOperations(
    op: 'get' | 'set' | 'increase',
    value?: number
  ) {
    const set = (value: any) =>
      sessionStorage.setItem(nextMessageCounter, JSON.stringify(value));
    const get = () =>
      JSON.parse(sessionStorage.getItem(nextMessageCounter) || '0');
    const nextMessageCounter = 'nextMessageCounter';

    if (op === 'increase') {
      const currentValue = get();
      set(currentValue + 1);
    }

    if (op === 'get') {
      return get();
    }

    if (op === 'set') {
      set(value);
    }
  }

  private keeperPollingBackupHandler(error?: Error): void {
    this.serviceUnavailable = true;
    this.unavailableTimestamp = new Date().getTime();

    this.log('Error.', error);
    this.log(
      'Notification Service is unavailable, switching to keeper polling method.'
    );

    let pollingIntervalsFromLocalStorage:
      | { [status: string]: number }
      | undefined = this.load('pollingIntervals');

    let pollingIntervals: { [status: string]: number } = {};

    if (!pollingIntervalsFromLocalStorage) {
      pollingIntervals = this.KEEPER_POLLING_INTERVALS;
    } else {
      pollingIntervals = pollingIntervalsFromLocalStorage;

      Object.keys(this.KEEPER_POLLING_INTERVALS).forEach((key) => {
        if (!pollingIntervals[key])
          pollingIntervals[key] = this.KEEPER_POLLING_INTERVALS[key];
      });
    }

    Object.entries(pollingIntervals).forEach(([status, time]) => {
      this.keeperPollingLoop(status, time as number);
    });
  }

  private keeperPollingLoop(status: string, time: number): void {
    setTimeout(() => this.keeperPolling(status, time), time);
  }

  private keeperPolling(status: string, time: number) {
    if (!this.serviceUnavailable) {
      return;
    }

    if (this.shouldRetrySNS()) {
      this.init();
      return;
    } else if (
      window.location.pathname.includes('pending-orders') &&
      !this.hasIntervalList(status)
    ) {
      this.keeperService
        .post(
          `${environment.keeperUrl}/pending-orders/${this.country}/${this.language}/${status}`,
          {}
        )
        .finally(() => {
          this.keeperPollingLoop(status, time);
        });
    } else {
      this.keeperPollingLoop(status, time);
    }
  }

  private log(...args: any[]): void {
    console.log('[SSE]', ...args);
  }

  private shouldRetrySNS(): boolean {
    const reconnectTimestamp =
      Date.now() - this.RECONNECT_ATTEMPT_MINUTES * 60 * 1000;

    if (
      this.unavailableTimestamp &&
      this.unavailableTimestamp < reconnectTimestamp
    ) {
      this.serviceUnavailable = false;
      this.unavailableTimestamp = 0;
      return true;
    }

    return false;
  }

  private hasIntervalList(status: string) {
    return this.load(`intervalList-${status}`)?.length > 0;
  }

  private clearPollingDivPartialTimerUpdate(status: string) {
    const storageName = `intervalList-${status}`;
    const intervalList = this.load(storageName) || [];
    if (!intervalList?.length) return;
    for (const interval of intervalList) {
      window.clearInterval(interval);
    }

    window.localStorage.removeItem(storageName);
  }

  private clearAllPollingDivPartialTimerUpdate() {
    Object.keys(this.KEEPER_POLLING_INTERVALS).forEach((status) => {
      this.clearPollingDivPartialTimerUpdate(status);
    });
  }

  private setCountryAndLanguageAndToken() {
    this.country = this.load('country');
    this.language = this.load('language');
    const auth = this.load('auth');
    this.token = auth?.token;
  }
}
